<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>Yetkili Kişiler</b-card-title>
      </b-card-header>
      <b-card-body>
        <template v-if="dataItem.related_persons.length > 0">
          <b-row
            v-for="(person,key) in dataItem.related_persons"
            :key="key"
            class="d-flex align-items-center"
          >
            <b-col>
              <related-person-types :item-key="key" />
            </b-col>
            <b-col>
              <name :item-key="key" />
            </b-col>
            <b-col>
              <phone :item-key="key" />
              <template v-if="person.id">
                <b-button
                  v-if="person.kvkk !== '1'"
                  variant="flat-danger"
                  size="sm"
                  @click="showKVKKModal(person,key)"
                >
                  <feather-icon icon="InfoIcon" />
                  KVKK onayı al
                </b-button>
                <b-badge
                  v-else
                  variant="light-success"
                >
                  <feather-icon icon="CheckIcon" />
                  KVKK
                </b-badge>
              </template>
            </b-col>
            <b-col>
              <email :item-key="key" />
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="flat-primary"
                class="btn-icon"
                pill
                :disabled="key === 0"
                @click="removeRow(key)"
              >
                <FeatherIcon icon="TrashIcon" />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <b-alert
          v-else
          show
          variant="info"
          class="m-1"
        >
          <div class="alert-body text-center">
            Müşteriye ilişkin yetkili kişi bilgisi bulunmamaktadır.
          </div>
        </b-alert>
      </b-card-body>
      <b-card-footer>
        <b-button
          size="sm"
          variant="primary"
          @click="addRow"
        >
          <FeatherIcon icon="PlusIcon" />
          Kişi Ekle
        </b-button>
      </b-card-footer>
    </b-card>
    <b-modal
      v-model="kvkkModal.status"
      title="KVKK Onayı"
      centered
      no-close-on-esc
      no-close-on-backdrop
    >
      <customer-relation-kvkk :person="kvkkModal.index" />
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="kvkkModal.status=false"
        >
          Tamam
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardFooter, BButton, BAlert, BModal, BBadge,
} from 'bootstrap-vue'
import RelatedPersonTypes from '@/views/Customers/components/elements/RelatedPerson/RelatedPersonTypes.vue'
import Name from '@/views/Customers/components/elements/RelatedPerson/Name.vue'
import Phone from '@/views/Customers/components/elements/RelatedPerson/Phone.vue'
import Email from '@/views/Customers/components/elements/RelatedPerson/Email.vue'
import CustomerRelationKvkk from '@/views/Customers/components/elements/RelatedPerson/Kvkk.vue'

export default {
  name: 'RelatedPersons',
  components: {
    CustomerRelationKvkk,
    BAlert,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BButton,
    BModal,
    RelatedPersonTypes,
    Name,
    Phone,
    Email,
    BBadge,
  },
  data() {
    return {
      kvkkModal: {
        index: null,
        status: false,
        phone: null,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/getCustomer']
    },
  },
  methods: {
    addRow() {
      this.dataItem.related_persons.push({
        id: null,
        name: null,
        phone: null,
        phone_region_code: 90,
        phone_region_iso2: 'TR',
        email: null,
      })
    },
    removeRow(key) {
      if (this.dataItem.related_persons[key].id) {
        this.$store.dispatch('customerRelatedPerson/removeData', this.dataItem.related_persons[key].id)
      }
      this.dataItem.related_persons.splice(key, 1)
    },
    showKVKKModal(person, index) {
      this.kvkkModal = {
        index,
        status: true,
        phone: person.phone,
      }
    },
  },
}
</script>
