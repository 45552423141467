<template>
  <b-card title="Diğer Bilgiler">
    <b-row>
      <b-col v-if="customer.id_com_customer_type === '1'">
        <job />
      </b-col>
      <b-col v-if="customer.id_com_customer_type === '2'">
        <sector />
      </b-col>
      <b-col>
        <meet />
      </b-col>
      <b-col
        v-if="!customer.id"
        cols="12"
      >
        <user-fw />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Job from '@/views/Customers/forms/Job'
// eslint-disable-next-line import/extensions
import Sector from '@/views/Customers/forms/Sector'
// eslint-disable-next-line import/extensions
import Meet from '@/views/Customers/forms/Meet'
// eslint-disable-next-line import/extensions
import UserFw from '@/views/Customers/forms/UserFw'

export default {
  name: 'Other',
  components: {
    BCard,
    BRow,
    BCol,
    Job,
    Sector,
    Meet,
    UserFw,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>

<style scoped>

</style>
