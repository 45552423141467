<template>
  <div>
    <b-alert
      v-if="customer.logo_id"
      show
      variant="info"
      class="mt-1"
    >
      <div class="alert-body text-center">
        <div>
          <feather-icon icon="InfoIcon" />
        </div>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            Müşteriye ait muhasebe departmanında cari hesap bulunmaktadır. Yapacağınız değişiklikleri muhasebe departmanına bildirmeniz gerekebilir.
          </b-col>
          <b-col>
            <b-button
              class="m-1"
              size="sm"
              variant="info"
              @click="modalStatus=true"
            >
              Aktarım Kayıtlarını Gör
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-modal
      v-model="modalStatus"
      title="Logo Aktarım Kayıtları"
      ok-only
      centered
      size="xl"
      no-close-on-backdrop
    >
      <b-table
        responsive="sm"
        :fields="fields"
        :items="customer.logoSendLogs"
        striped
        hover
        small
      >
        <template #cell(username)="data">
          {{ data.item.username }}
          <div v-if="data.item.created">
            <small class="text-warning">{{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
          </div>
        </template>
      </b-table>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="modalStatus=false"
        >
          Kapat
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BAlert, BButton, BRow, BCol, BTable,
} from 'bootstrap-vue'

export default {
  name: 'LogoAlertCard',
  components: {
    BTable,
    BAlert,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      modalStatus: false,
      fields: [
        {
          key: 'name',
          label: 'Müşteri / Firma Adı',
        },
        {
          key: 'phone',
          label: 'Telefon',
        },
        {
          key: 'tckn',
          label: 'Tckn/Vergi No',
        },
        {
          key: 'email',
          label: 'E-Posta',
        },
        {
          key: 'response',
          label: 'Sonuç',
        },
        {
          key: 'username',
          label: 'Gönderen',
        },
      ],
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>
