<template>
  <div>
    <hr>
    <b-form-group>
      <label
        class="text-primary"
        for="id_com_user_fw"
      >
        Danışman Atama
      </label>
      <v-select
        id="id_com_user_fw"
        v-model="customer.id_com_user_fw"
        label="title"
        :reduce="user => user.id"
        :options="users"
        placeholder="Danışman Atama"
      />
    </b-form-group>
    <b-alert
      show
      variant="primary"
    >
      <div class="alert-body">
        <FeatherIcon icon="InfoIcon" />
        Bu alan danışman adına müşteri kartı oluşturmak için açılmıştır. Alanı boş bırakırsanız müşteri kartı sizin adınıza açılacaktır.
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BFormGroup, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Job',
  components: {
    BFormGroup,
    BAlert,
    vSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: ['com_user.id AS id', 'com_user.name AS title'],
        where: {
          'com_user.status': 1,
          'com_user.id !=': this.userData.id,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
