<template>
  <b-card>
    <b-row>
      <b-col>
        <b-card-title>Kişi Bilgileri</b-card-title>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="customer.detailForm"
          name="check-button"
          switch
          inline
        >
          Detaylı Form
        </b-form-checkbox>
      </b-col>
    </b-row>
    <tckn v-if="customer.id_com_customer_type === '1'" />
    <tax-number v-if="customer.id_com_customer_type === '2'" />
    <customer-type />
    <b-row>
      <b-col
        v-if="customer.id_com_customer_type === '2'"
        sm="12"
        md="6"
      >
        <company-name />
      </b-col>
      <b-col
        v-if="customer.id_com_customer_type === '2'"
        sm="12"
        md="6"
      >
        <name-surname />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="customer.id_com_customer_type === '1'"
        cols="12"
        md="6"
      >
        <name-surname />
      </b-col>
      <b-col
        v-if="customer.id_com_customer_type === '1'"
        cols="12"
        md="6"
      >
        <birthday />
      </b-col>
      <b-col>
        <phone />
      </b-col>
      <b-col v-if="customer.detailForm">
        <phone2 />
      </b-col>
      <b-col>
        <email />
      </b-col>
      <b-col
        v-if="customer.detailForm"
        cols="12"
      >
        <notes />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import Tckn from '@/views/Customers/forms/Tckn.vue'
import TaxNumber from '@/views/Customers/forms/TaxNumber.vue'
import CustomerType from '@/views/Customers/forms/CustomerType.vue'
import CompanyName from '@/views/Customers/forms/CompanyName.vue'
import NameSurname from '@/views/Customers/forms/NameSurname.vue'
import Birthday from '@/views/Customers/forms/Birthday.vue'
import Phone from '@/views/Customers/forms/Phone.vue'
import Phone2 from '@/views/Customers/forms/Phone2.vue'
import Email from '@/views/Customers/forms/Email.vue'
import Notes from '@/views/Customers/forms/Notes.vue'

export default {
  name: 'Personal',
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckbox,
    Tckn,
    TaxNumber,
    CustomerType,
    CompanyName,
    NameSurname,
    Birthday,
    Phone,
    Phone2,
    Email,
    Notes,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>

<style scoped>

</style>
