<template>
  <b-form-group
    label="Ünvan"
    :label-for="'id_related_person_types_' + itemKey"
  >
    <v-select
      :id="'id_related_person_types_' + itemKey"
      v-model="dataItem.related_persons[itemKey].id_com_related_person_type"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
    />
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'RelatedPersonTypes',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/getCustomer']
    },
    dataList() {
      return this.$store.getters['relatedPersonType/dataList']
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    getDataList() {
      this.$store.dispatch('relatedPersonType/getDataList', {
        select: [
          'com_related_person_type.id AS id',
          'com_related_person_type.title AS title',
        ],
      })
    },
  },
}
</script>
