<template>
  <b-form-group
    label="Müşteri Notu"
    label-for="notes"
  >
    <b-form-textarea
      id="notes"
      v-model="customer.notes"
      placeholder="Müşteri Notu"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'Notes',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>

<style scoped></style>
