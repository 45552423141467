<template>
  <b-card
    title="Fatura Bilgileri"
  >
    <b-row>
      <b-col
        v-if="customer.id_com_customer_type === '1'"
        cols="6"
      >
        <tckn />
      </b-col>
      <b-col
        v-if="customer.id_com_customer_type === '2'"
        cols="6"
      >
        <tax-number />
      </b-col>
      <b-col
        cols="6"
      >
        <tax-admin />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Tckn from '@/views/Customers/forms/Tckn'
// eslint-disable-next-line import/extensions
import TaxNumber from '@/views/Customers/forms/TaxNumber'
// eslint-disable-next-line import/extensions
import TaxAdmin from '@/views/Customers/forms/TaxAdmin'
// eslint-disable-next-line import/extensions

export default {
  name: 'Invoice',
  components: {
    BCard,
    BRow,
    BCol,
    Tckn,
    TaxNumber,
    TaxAdmin,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>

<style scoped>

</style>
